.error_image_container {
    display: flex;
    max-width: 80%;
    height: 100%;
    justify-content: center;
    margin: auto;
}

.error_image_content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.error_image_content img {
    width: 50%;
}

.error_image_content p {
    font-size: 20px;
    text-align: center;
    font-family: 'Lexend', Tahoma, sans-serif;
}