.spinner {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
}
.spinner:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-top: 4px solid black;
    border-left: 4px solid black;
    border-bottom: 4px solid black;
    border-right: 4px solid transparent;
    border-radius: 100%;
    animation: spinner_turn linear .8s infinite;
}

@keyframes spinner_turn {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
}
