.table_overview {
    font-size: 0.9em;
    width: 100%;
    border-collapse: collapse;
    background-color: #f9f9f9;
    border-radius: 3px;
    padding: 15px;
}

.table_overview_head th {
    background: white;
    position: sticky;
    overflow: hidden;
}

.table_overview thead th {
    z-index: 2;
    top: 0;
}

.table_overview thead tr {
    text-align: left;
}

.table_overview th,
.table_overview td {
    padding: 2px 5px;
    font-size: small;   
}

.table_overview .table_odd {
    background-color: #ffffff;
}

.table_overview .table_even {
    background-color: #f0f0f0;
}

.group_by_selectors {
    border: #1a1a1a 1px solid;
    border-radius: 3px;
    flex: 1;
    margin-right: 20px;
    padding: 5px;
}

.group_by_selectors h3 {
    margin: 2px 0;
}

.groupby_line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
}

.groupby_column{
    cursor: pointer;
}

.right {
    justify-content: flex-end;
}

.groupby_value {
    text-align: right;
}

.groupby_total {
    font-weight: bold;
}

.groupby_margin_left {
    margin-left: 5px;
}

.group_by_no_wrap {
    display: flex;
    flex-wrap: nowrap;
}

.group_by_selects{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 50%;
}

.group_by_selects select{
    max-width: 100%;
    height: 100%;
}

.group_by_selection_card {
    max-height: 300px;
    overflow-y: scroll;
    margin-bottom: 15px;
}

.group_by_percentage_button{
    display: flex;
    align-items: center;
    gap: 5px;
    align-self: center;
    background-color: transparent;
    color: gray
}

.group_by_percentage_button:hover{
    background-color: var(--color-button-hover);
}

.group_by_percentage_button.selected{
    color: black;
}
