@import "../../../views/ResultsView/ResultsView.scss";

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.skeleton_block {
  @extend .result_block;
  background: white;

  .skeleton_animation {
    background: linear-gradient(
                    90deg,
                    #f0f0f0 25%,
                    #e0e0e0 37%,
                    #f0f0f0 63%
    );
    background-size: 1000px 100%;
    animation: shimmer 2s infinite linear;
  }

  .skeleton_table {
    flex: 1;

    .skeleton_row {
      display: flex;
      margin-bottom: 12px;

      .skeleton_cell {
        @extend .skeleton_animation;
        height: 20px;
        margin-right: 15px;
        border-radius: 4px;

        &.header {
          height: 24px;
          margin-bottom: 20px;
        }

        &.title {
          width: 200px;
        }

        &.data {
          width: 80px;
        }
      }
    }
  }

  .skeleton_chart {
    flex: 1;

    .chart_container {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      height: 100%;
    }

    .chart_bar {
      @extend .skeleton_animation;
      width: 4%;  // Adjust based on the number of bars
      min-width: 15px;
      border-radius: 4px 4px 0 0;
      transform-origin: bottom;
    }
  }

  .skeleton_header {
    @extend .skeleton_animation;
    width: 300px;
    height: 24px;
    border-radius: 4px;
    margin-bottom: 20px;
  }
}