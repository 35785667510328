@mixin title-styles {
	width: 100%;
	font-weight: bold;
}

@mixin green-button {
	background: var(--user-styling-color-darken-mary);
	color: white;
}


.modes_buttons {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 0.5rem;
	width: 100%;
	min-width: 20rem;
	&__title {
		@include title-styles;
		color: var(--gray);
		background-color: transparent;

		&__selected, &:hover {
			@include title-styles;
			@include green-button;
		}
	}
}