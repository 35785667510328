@mixin selected_element {
    color: #1a1a1a;
    background: #eff1f0;
    border-radius: 20px 0 0 20px;
}

@mixin separator {
    width: 90%;
    align-self: flex-start;
    border: 1px solid var(--lightGray);
    margin-left: 0;
}


.sidebar {
    font-family: 'Roboto', Tahoma, sans-serif;
    width: 100%;
    height: 100%;
    margin: 0;
    box-sizing: border-box;
    padding: 10px 0 0;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    color: var(--gray);
    justify-content: space-between;
}

.sidebar_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: stretch;
    overflow: hidden;
    margin: 10% 0 0 10%;
    position: relative;
}
.sidebar_container a {
    text-decoration: none;
    color: var(--gray);
}

.sidebar_head {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: bold;
    flex-shrink: 0;
}

.sidebar_head a svg {
    height: 20px;
    width: 20px;
}

.sidebar_head a {
    display: flex;
    gap: 15px;
    margin: 5px 15px 5px 5px;
}

.line_separator {
    @include separator;
}

.sidebar_content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.sidebar_dynamic_pages {
    flex: 0 1 auto;
    overflow-y: auto;
    max-height: calc(100vh - 200px);
    padding-bottom: 10px;
}

.sidebar_content_title {
    direction: ltr;
    text-decoration: none;
    margin: 10px 0;
    display: flex;
    gap: 5px;
}

.sidebar_content_title a {
    display: flex;
    flex-direction: row;
    text-align: start;
    gap: 5px;

    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    color: var(--user-styling-color-light-mary);
}
.sidebar_content_title a svg {
    align-self: center;
}

.sidebar_content_subtitle {
    display: flex;
    flex-direction: column;
    padding: 10px 5px 10px 0;
    margin-left: 15px;
    background: none;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--gray);
    &.current {
        @include selected_element;
    }
    a {
        margin-left: 15px;
    }

    &.help {
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        padding: 10px;
        width: calc(100% - 10px);
        box-sizing: border-box;
        overflow: hidden;
        margin-left: 0;
        cursor: pointer;
        &:hover {
            @include selected_element;
        }
    }
}

.sidebar_footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    max-width: 100%;
    width: 100%;
    margin-top: auto;
    padding-bottom: 10px;
}

.sidebar_logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    width: 100%;
}

.sidebar_logo img {
    width: 50px;
    height: 50px;
    margin-left: 10%;
}

.sidebar_logo_name {
    font-weight: bold;
    font-size: 20px;
    color: black;
}

.sidebar_static_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin: 20px 0 5px 0;
    gap:10px;
}

.sidebar_static_pages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: 10px;
}

.sidebar_static_container {
    color: var(--gray);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
}

.cgu_link {
    cursor: pointer;
}

.help_panel {
    position: absolute;
    width: 300px;
    background: white;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 2000;
    border-radius: 5px;
    padding: 15px;
    border: 1px solid #ddd;

    max-height: unset;
    height: auto;
    
    .help_panel_content {
        padding: 10px;

        h2 {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 15px;
        }

        ul {
            list-style: none;
            padding: 0;

            a, button.cgu_link {
                padding: 10px 10px;
                width: 100%;
                cursor: pointer;
                text-decoration: none;
                color: inherit;
                display: inline-block;
                background: none;
                border: none;
                font-size: inherit;
                text-align: left;
                background-color: white;

                &:hover {
                    @include selected_element;
                }
            }
        }

        hr {
            @include separator;
        }

        p {
            font-size: 12px;
            color: gray;
        }

        .close_button {
            background: none;
            border: none;
            font-size: 18px;
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
            color: black;
        }
    }
}
