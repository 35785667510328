.multi_choice_dropdown {
  border-radius: 5px;
  border: 1px solid #ccc;
  overflow-y: auto;
}

.multi_choice_dropdown_element {
  display: flex;
    flex-direction: column;
  width: 100%;
}

.multi_choice_dropdown_button {
  appearance: none;
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  background-color: transparent;
  text-align: left;
}

.multi_choice_dropdown_children {
  padding-left: 30px;
}

.multi_choice_dropdown_button:hover {
  background-color: #c2c2c2;
}