.contact_view {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 20px;
	gap: 20px;
	
    .contact_study {
		padding: 20px;
		max-width: 1300px;
        background-color: #f9f9f9;
        border-radius: 5px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

		.contact_study_title {
			font-size: 26px;
			color: var(--user-styling-color-darken-mary);
			margin: 0;
			padding: 10px;
		}

        table {
            width: 100%;
            border-collapse: collapse;
            text-align: left;
			gap: 10px;

            th {
                font-size: 18px;
                color: var(--gray);
				font-weight: bold;
				width: 33%;
				padding: 10px;
				.contact_column_title{
					display: flex;
					gap: 10px;
				}
            }

            .contact_consultant {
                border-top: 1px solid #ccc;

                td {
					gap: 5px;
                    font-size: 16px;
                    color: var(--gray);
					width: 33%;
					padding: 10px;
					a{
						color: var(--user-styling-color-darken-mary);
					}
                }

				&:last-child {
					border-bottom: none
				}
            }
        }
    }
}
