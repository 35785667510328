.dashboard_results {
    padding: 20px;
    background-color: #f9f9f9;
}

.dashboard_results .buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

button.dashboard_create_table {
    position: relative;
    margin: 10px;
    width: 350px;
    height: 120px;
    color: black;
    font-size: 12px;
    border-radius: 10px;
    cursor: pointer;
    padding: 40px 20px 40px 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    background-origin: border-box;
    border: 2px solid transparent;
}

button.dashboard_create_table img {
    position: absolute;
    bottom: 0;
    left: 0;

}

button.dashboard_create_table.long::before {
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translateY(-50%);
    content: '+';
    font-size: 50px;
    font-weight: bolder;
    margin-right: 10px;
    color: #4CAF50;
}

button.dashboard_create_table h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    text-align: left;
}
button.dashboard_create_table p {
    margin: 0;
}

button.dashboard_create_table.long {
    width: 350px;
    padding: 40px 60px 40px 90px;
}
button.dashboard_create_table.short {
    width: 250px;
}
button.dashboard_create_table.green_table {
    background-image: linear-gradient(to right, #ffffff, #ebf6f3);
    border: 2px solid #bcece5;
}
button.dashboard_create_table.green_light {
    background-image: linear-gradient(to right, #ffffff, #bcece5);
    border: 2px solid #4CAF50;
}
button.dashboard_create_table.green {
    background-image: linear-gradient(to right, #f3f2f2, #b2c7b9);
}
button.dashboard_create_table.yellow {
    background-image: linear-gradient(to right, #f3f2f2, #f1e49d);
}
button.dashboard_create_table.blue {
    background-image: linear-gradient(to right, #f3f2f2, #a2bdc7);
}
button.dashboard_create_table.red {
    background-image: linear-gradient(to right, #f3f2f2, #efc2c2);
}

button.dashboard_create_table:not(.disabled):hover {
    filter: brightness(1);
    border: #1a1a1a 2px solid;
}
button.dashboard_create_table.disabled {
    filter: grayscale(1) brightness(1);
    cursor: not-allowed;
}
button.dashboard_create_table.disabled:hover {
    filter: grayscale(1) brightness(1);
}

.dashboard_modal {
    padding: 20px;

    .alert {
        margin: 10px 0;
    }

    &__studies {
        display: flex;
        flex-direction: column;
        margin: 20px 0 10px 0;
        gap: 5px;
    }
}

.dashboard_modal .choice_selector,
.dashboard_modal .search_bar {
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.dashboard_date {
    display: flex;
    gap: 10px;
}

.choice_selector_wrapper {
    width: inherit;
    position: fixed;
    z-index: 1000;
    display: none;
    overflow-y: auto;
    background-color: #eff1f0;
}

.choice_selector_wrapper.open {
    display: flex;
}

.dashboard_save_button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.dashboard_modal_dataset {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.dashboard_list {
    .buttons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}
.dashboard_pin_modal_list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 5px;
    box-sizing: border-box;

    .dashboard_pin_modal_list_item_button {
        width: 100%;
        box-sizing: border-box;
        &:hover {
            filter: none;
            background-color: var(--user-styling-color-darken-mary);
            color: white;
        }

        .dashboard_pin_modal_list_item {
            padding: 10px;
            border: 1px solid #e0e0e0;
            border-radius: 5px;
            box-sizing: border-box;

            .dashboard_pin_modal_list_item_name {
                font-weight: bold;
                margin: 5px 2px;
            }

            .dashboard_pin_modal_list_item_desc {
                font-size: 12px;
                margin: 5px 0;
                font-weight: normal !important;
            }
        }
    }

    .dashboard_pin_modal_success{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        i {
            color: green;
            font-size: 2em;
        }

    }
}

button.dashboard_share_button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: gray;

    .dashboard_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        border-radius: 50px;
        border: 2px solid gray;
        font-size: 1.2em;
    }
    &:hover {
        color: black;
        .icon {
            border: 2px solid black;
        }
    }
}

.modal_dashboard_share {
    width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    max-height: 60vh;

    .modal_dashboard_share_filter_input {
        width: 100%;
    }
    .modal_dashboard_share_list {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 5px;
        box-sizing: border-box;

        .modal_dashboard_share_user {
            width: 100%;
            box-sizing: border-box;
            padding: 10px;
            border: 1px solid #e0e0e0;
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            gap: 5px;

            &.selected {
                background-color: var(--user-styling-color-light);
            }

            &:hover {
                filter: none;
                background-color: var(--user-styling-color-darken-mary);
                color: white;
            }
        }
    }
}

.dashboard_modal__selected_dataset__title {
    font-weight: bold;
    font-size: 0.9em;
    margin: 5px 2px;
}

.dashboard_modal__selected_datasets {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 5px;
    box-sizing: border-box;

    .dashboard_modal__selected_dataset {
        border-radius: 15px;
        padding: 10px;
        background-color: #f9f9f9;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 250px;
        height: 150px;
        text-align: center;

        &:hover {
            filter: none;
            background-color: var(--user-styling-color-darken-mary);
            color: white;
        }

        .icon {
            font-size: 3em;
            margin: 10px;
            &.small {
                font-size: 2em;
            }
        }
    }
}

.dashboard_evolution_modal_table {
    margin: 20px 0;
    td, th {
        padding: 5px;
    }
}