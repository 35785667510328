.cgu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  margin: auto;

  .btn-primary {
    background: var(--user-styling-color-darken-mary);
    color: white;
  }

  .showCgu {
    display: flex;
    gap: 5px;
    width: fit-content;
    align-self: center;
    margin: 1rem;
  }


  .actions {
    display: flex;
    align-self: center;
    gap: 20px;

    .btn {
      width: 150px;
      align-self: center;
    }
  }
}