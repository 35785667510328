html, body, #root {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', Tahoma, sans-serif;
}

:root {
    --user-styling-color: #52c78a; /* 52c78a */
    --user-styling-color-light: hsl(149, 51%, 75%); /* 52c78a */
    --user-styling-color-table-head: hsl(149, 51%, 95%); /* 52c78a */
    --user-styling-color-darken: hsl(149, 51%, 35%); /* 52c78a */
    --user-styling-color-light-mary: #797A7A; /* temporary name until we change the colors everywhere */
    --user-styling-color-darken-mary: #47835B; /* temporary name until we change the colors everywhere */
    --user-styling-color-danger-mary: #D01A1A;
    --color-button-hover: #cecece;
    --font-family: 'Lexend', Tahoma, sans-serif;
    --yellow-corp-color: #ffde55;
    --red-corp-color: #E74C3C;
}

h1, h2, h3, h4, h5 {
    font-family: 'Lexend', Tahoma, sans-serif;
}

h1 {
    font-size: 24px;
}

h2 {
    font-size: 18px;
}

h3 {
    font-size: 16px;
}

*::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: rgba(0, 0, 0, 0.16);
    border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 20px;
}

*::-webkit-scrollbar-track-piece {
    color: #212529;
}

$resilio-standard-screen-width: 1280px;

.text-center {
    text-align: center;
}

.text-bolder {
    font-weight: bolder;
}

.df-col {
    display: flex;
    flex-direction: column;
}

.df-row {
    display: flex;
    flex-direction: row;
}

.jc-bwn {
    display: flex;
    justify-content: space-between;
}

.home_study_title_link, .study_dataset_title_head a {
    text-decoration: none;
    color: black;
}

.home_study_title_link:hover, .study_dataset_title_head a:hover {
    color: black;
    font-weight: bold;
}

.filter_input_box {
    margin: 10px 0;
}

.filter_input_box input {
    border-radius: 3px;
    border: lightgrey solid 1px;
    background-color: white;
    padding: 5px 10px;
    width: 18em;
}

.home_study_list_count {
    font-size: 0.7em;
    color: #6b6b6b;
}

.home_dataset_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.home_dataset_bar label {
    font-size: 11px;
    font-weight: bold;
}

.home_dataset_bar {
    flex: 1 1 auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.home_dataset_button {
    text-decoration: none;
    color: black;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 2px;
    /*background-color: #E2F1E7;*/
    background-color: var(--user-styling-color-table-head);
    padding: 5px 15px;
    width: 250px;
}

.home_dataset_button:hover {
    color: black;
    border-color: var(--user-styling-color-darken);
}

.block_folder {
    position: relative;
    margin: 15px 1px;
}

.block_folder_tab {
    position: absolute;
    top: -14px;
    left: -1px;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.card_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card {
    border-radius: 3px;
    border: 1px solid var(--user-styling-color-light);
    display: flex;
    flex-direction: column;
}

.card_header, .card_body, .card_footer, .card > img {
    padding: 12px 16px;
}

.card_header {
    background-color: hsl(0, 0%, 95%);
}

.card_body {
}

.card_title {
}

.card_subtitle {
}

.card_footer {
    background-color: hsl(0, 0%, 95%);
    color: #6c757d;
}

.row {
    display: flex;
    flex-direction: row;
}

.col {
    display: flex;
    flex-direction: column;
}

.jc-center {
    justify-content: center;
}

.jc-start {
    justify-content: flex-start;
}

.jc-end {
    justify-content: flex-end;
}

.ai-center {
    align-items: center;
}

.ai-start {
    align-items: flex-start;
}

.ai-end {
    align-items: flex-end;
}

.modal {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(20px);
}

.modal_content {
    border: 1px solid var(--user-styling-color-light);
    border-radius: 3px;
    background-color: white;
    max-width: 80vw;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
}

.modal_header {
    padding: 12px 15px;
    background-color: hsl(0, 0%, 95%);
}

.modal_body {
    padding: 12px 15px;
    overflow: auto;
}

.modal_footer {
    padding: 12px 15px;
    display: flex;
    justify-content: flex-end;
}

.modal_number_page {
    display: flex;
    justify-content: center;
    gap: 5px;
    padding-bottom: 5px;
}

.modal_error {
    display: flex;
    justify-content: center;
    margin: 5px;
    background-color: hsl(355, 70%, 91%);
    color: darkred;
}

.modal_multi_selector {
    display: flex;
    flex-direction: column;
    margin: 6px;
    max-height: 300px;
}

.dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
}

.green_dot {
    background-color: #339e69;
}

.gray_dot {
    background-color: gray;
}

.alert {
    background-color: hsl(0, 0%, 95%);
    padding: .7rem .9rem;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .toggle-button {
        font-size: 0.9em;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        text-decoration: underline;
        color: var(--gray);

        &:hover {
            opacity: 0.8;
        }
    }
}

.alert_content {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.alert_danger {
    position: relative;
    background-color: hsl(355, 70%, 91%);
    color: darkred;

    .alert_close_button {
        position: absolute;
        background: none;
        color: darkred;
        top: -6px;
        right: -6px;
    }
}

.alert_success {
    background-color: hsl(152, 70%, 91%);
    color: darkgreen;
}

.alert_warning {
    background-color: hsl(39, 100%, 91%);
    color: rgb(162, 89, 0);
}

.alert_info {
    background-color: #cff4fc;
}

.alert_normal {
    background-color: hsl(191, 0%, 98%);
}

form {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

input[type=text],
input[type=email],
input[type=password],
input[type=number] {
    appearance: none;
    border-radius: 3px;
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-weight: 400;
    line-height: 1.5;
    border: 1px solid #ced4da;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    outline-color: #339E69;
}

input[type=text]:focus-visible,
input[type=email]:focus-visible,
input[type=password]:focus-visible,
input[type=number]:focus-visible {
    outline-style: solid;
}

a::-moz-focus-inner,
button::-moz-focus-inner,
input::-moz-focus-inner,
::-moz-focus-inner {
    border: 0;
}

input[type=text].error,
input[type=email].error,
input[type=password].error,
input[type=number].error {
    border-color: red;
}

input[type=checkbox] {
    margin: 0 4px;
    appearance: none;
    border: var(--gray) 1px solid !important;
    border-radius: 20px !important;
    width: 36px !important;
    height: 18px !important;
    position: relative;
    vertical-align: sub;
}

input[type=checkbox]:checked {
    border: var(--user-styling-color-darken-mary) 1px solid !important;
}

input[type=checkbox]:before {
    content: ' ';
    position: absolute;
    background-color: var(--gray);
    width: 22px;
    height: 17px;
    left: -1px;
    border-radius: 15px;
    transition: .2s;
    cursor: pointer;
}

input[type=checkbox]:checked:before {
    background-color: var(--user-styling-color-darken-mary);
    left: 13px;
}

select {
    border-radius: 3px;
    box-sizing: border-box;
    display: block;
    padding: .375rem .75rem;
    font-weight: 400;
    line-height: 1.5;
    border: 1px solid #ced4da;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    outline-color: var(--user-styling-color-darken-mary);
    cursor: pointer;
}

select:hover {
    border: 1px solid var(--user-styling-color-darken-mary);
}

button, input[type=button], a.button {
    cursor: pointer;
    padding: .6rem .8rem;
    font-size: 1rem;
    border-radius: 3px;
    border: none;
}

a.button {
    text-decoration: none;
}

button:hover, input[type=button]:hover, a.button:hover {
    filter: brightness(1.2);
}

button:active, input[type=button]:active, a.button:active {
    filter: brightness(0.7);
}

.button_danger {
    margin: 5px;
    color: white;
    background-color: #e00000;
}

.button_warning {
    margin: 5px;
    color: black;
    background-color: #FAEBA1;
}

.button_primary {
    margin: 5px;
    color: white;
    background-color: #339e69;
}

.button_disabled {
    margin: 5px;
    color: rgb(110, 110, 110);
    background-color: #e9e8e8;
    cursor: not-allowed;
}
.button_disabled:hover {
    filter: brightness(1);
}

.button_secondary {
    margin: 5px;
    color: white;
    background-color: #339E69;
}

.button_outline {
    margin: 5px;
    background-color: transparent;
    border: 1px solid grey;
}

.button_outline:hover {
    background-color: black;
    color: white;
}

.button_link {
    margin: 5px;
    color: black;
    padding: 0;
    cursor: pointer;
}

.button_link.button_sublist {
    font-family: 'Roboto', Tahoma, sans-serif;
    font-weight: normal;
}

.button_blank {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    text-align: inherit;
}

.button_none {
    appearance: none;
    border: none;
    background-color: transparent;
    color: black;
    padding: 0;
    cursor: pointer;
}

.button_title {
    background-color: transparent;
    border-radius: 10px;
    border: 1px solid;
    font-family: inherit;
    font-weight: inherit;
}

progress {
    appearance: none;
    border: none;
    background-color: hsl(0, 0%, 80%);
    border-radius: 3px;
    position: relative;
    vertical-align: middle;
}

progress::-webkit-progress-bar {
    border: none;
    background-color: hsl(0, 0%, 80%);
    border-radius: 3px;
}

progress::-moz-progress-bar,
progress::-webkit-progress-value,
progress[value] {
    border: none;
    border-radius: 3px;
}

progress.home_dataset_progress::-moz-progress-bar,
progress.home_dataset_progress::-webkit-progress-value {
    background-color: var(--user-styling-color);
    border-radius: 3px;
}

progress.home_dataset_equipment_progress::-moz-progress-bar,
progress.home_dataset_equipment_progress::-webkit-progress-value {
    background-color: var(--user-styling-color);
    border-radius: 3px;
}

progress.home_dataset_maturity_progress::-moz-progress-bar,
progress.home_dataset_maturity_progress::-webkit-progress-value {
    background-color: var(--user-styling-color);
    border-radius: 3px;
}

.instead_input {
    min-width: 80px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: hidden;
}

$borders-key: (
    m: margin,
    mt: margin-top,
    mr: margin-right,
    mb: margin-bottom,
    ml: margin-left,
    p: padding,
    pt: padding-top,
    pr: padding-right,
    pb: padding-bottom,
    pl: padding-left,
);

@each $key, $name in $borders-key {
    @for $i from 1 through 5 {
        .#{$key}#{$i} {
            #{$name}: #{$i * 3}px;
        }
    }
}

.o-h {
    overflow: hidden;
}

.pos-r {
    position: relative;
}

a.static {
    color: #2f9d68;
}

a.static:visited {
    color: #2f9d68;
}

.vertical-middle {
    vertical-align: middle;
}

.icon_tip {
    margin: auto 10px auto auto;
    height: 1.2em;
}

.overflow_y_scroll {
    overflow-y: scroll;
}

.overflow_y_auto {
    overflow-y: auto;
}

.flex_100 {
    flex: 1 1 100%;
}

.flex {
    display: flex;
}

.max-height {
    max-height: 100%;
}

.pink {
    color: pink;
}

.yellow {
    color: yellow;
}

.green {
    color: #2f9d68;
}

.paddingR {
    padding-right: 70px;
}

.center-last {
    text-align-last: left;
    padding-left: 10px;
    margin: 0;
}

.red {
    color: var(--user-styling-color-danger-mary);
}

.green_mary {
    color: var(--user-styling-color-darken-mary);
}

.spacing_tooltip {
    width: 6px;
    margin-right: 5px
}

.info {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    gap: 0.5rem;
    width: 100%;
}