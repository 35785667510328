
.search_bar {
    width: 500px;
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}

.search_bar input[type="text"] {
    border: none;
    outline: none;
    background: transparent;
}

.search_bar input[type="text"]:focus {
    border: none;
    outline: none;
}