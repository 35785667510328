.tip_container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.toggle-button-container {
	display: flex;
	justify-content: center;
	margin-top: 10px;
}

.toggle-button {
	background: none;
	border: none;
	color: var(--gray);
	cursor: pointer;
	text-decoration: underline;
	padding: 5px 10px;
}

.toggle-button:hover {
	opacity: 0.8;
}