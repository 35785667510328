.login_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.login {
    display: flex;
    flex-direction: column;
    background: #FEFFFE;
    align-items: center;
    width: 1000px;
    border: 1px solid #606161;
    border-radius: 4px;
}

.login_header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #FEFFFE;
    margin: 10px 0 10px 0;
    gap: 5px;
}

.login_body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 40%;
    gap: 5%;
    margin: 10px 0 10px 0;
    border-top: 1px solid var(--gray);
}

.maintenance {
    flex-direction: column;
}

.login_resilio {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.login_footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.login_status {
    margin-top: 8px;
}

.app_version {
    margin: 5px;
    font-size: 9px;
    color: var(--gray);
}

.connect {
    font-size: 20px;
    margin: 30px 0 30px 0;
}

.form_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.login_input_element {
    width: 100%;
}

.login_footer {
    width: 100%;
    display: flex;
    margin-top: 30px;
}

.login_footer_buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    color: var(--gray);
}

.form_footer_static {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0 10px 0;
    gap: 10px;
    font-size: 12px;
    color: var(--gray);
    text-decoration: underline;
    flex-wrap: wrap;
}

.login_footer a, .form_footer_static a {
    font-size: 12px;
    text-decoration: none;
    color: var(--gray);
}

.language {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.subtitle {
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 13px;
    font-style: italic;
    color: var(--gray);
}

.valid_button {
    display: flex;
    align-self: flex-end;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 32px;
    background: var(--user-styling-color-light-mary);
    color: white;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
}

.valid_button.ready {
    background: #2f9d68;
}

.login_forgot_password {
    font-size: 11px;
}

.login_contact_message {
    font-size: 13px;
    font-weight: bold;
    color: var(--gray);
    margin-top: 30px;
}

.login_contact_message a {
    color: var(--gray);
}

.cgu_link {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: underline;
    font-size: 13px;
}
