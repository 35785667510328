.template_content {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 210px repeat(4, 1fr);
    grid-template-rows: min-content repeat(4, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
    position: relative;
    overflow: hidden;
}

.template_side {
    grid-area: 1 / 1 / 6 / 2;
}

.template_core {
    background-color: #eff1f0;
    grid-area: 1 / 2 / 6 / 6;
    display: flex;
    flex-direction: column;
    min-width: 740px;
    position: relative
}

.core_block {
    height: 100%;
    overflow-y: scroll;
    padding: 0 20px 25px 20px;
    scroll-behavior: smooth;
}

.core_block > * {
    pointer-events: auto;
}

main {
    outline: none;
    border: none;
}
