.dashboard_overview_table {
  .titles {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 15px;
    .buttons {
      display: flex;
      flex-direction: row;
      gap: 20px;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        color: gray;

        .dashboard_icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          box-sizing: border-box;
          border-radius: 50px;
          border: 2px solid gray;
          font-size: 1.2em;
        }
        &:hover {
          color: black;
          .icon {
            border: 2px solid black;
          }
        }
      }
    }
  }
  .description {
    font-size: 1.1rem;
    font-weight: 500;
    border-left: 3px solid dimgray;
    padding-left: 1rem;
  }

  .cards {
    border: none;
  }

  .dashboard_card {
    position: relative;
    .explanation {
      margin-bottom: 10px;
    }
    textarea {
      width: 100%;
      resize: vertical;
    }
    .buttons {
      position: absolute;
      top: 5px;
      right: 10px;
      display: flex;
      flex-direction: row;
      gap: 20px;

      button {
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        font-size: .8em;
        color: gray;

        .dashboard_icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25px;
          height: 25px;
          box-sizing: border-box;
          border-radius: 50px;
          border: 1px solid gray;
        }
        &:hover {
          color: black;
          .icon {
            border: 1px solid black;
          }
        }
        &:focus {
          opacity: 1;
        }
      }
    }

    &:hover {
      button {
        opacity: 1;
      }
    }
  }
}

.modal_edit_dashboard_footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}