:root {
  --hl2: #FAEBA1;
  --hl1: #FC63B2;
  --darkGreen: #05452E;
  --lightGreen: #52C78A;
  --green: #339E69;
  --gray: #494A4A;
  --lightGray: #E0E0E0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.corpGreen {
    color: var(--green);
}

.corpDarkGreen {
    color: var(--darkGreen);
}

.corpLightGreen {
    color: var(--lightGreen);
}

.corpHightlightTwo {
    color: var(--hl2)
}

.corpHightlightOne {
    color: var(--hl1)
}

/* Override Boostrap class */
.modal-sm {
    max-width: 100px !important;
}
