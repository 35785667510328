.result_card_error {
    display: flex;
    width: 700px;
    height: 100%;
    flex-direction: column;
    align-items: center;
    align-self: center;
}

.result_card_error img {
    max-width: 100%;
    max-height: 80%;
}

.result_card_error p {
    font-size: 15px;
    font-family: 'Lexend', Tahoma, sans-serif;
    text-align: center;
}
