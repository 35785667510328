@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.results_grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 25px; // If you change this value, change the width of the result_block:nth-child(odd):last-child in result_block class

    &.group_by {
        margin-top: 25px;
        margin-bottom: 25px;
    }
}

.result_panel_head {
    padding: 50px;

    .result_panel_title {
        display: flex;
        justify-content: center;
        align-items: center;

        hr {
            width: 30%;
            background-color: var(--green);
            border: none;
            height: 1px;
        }
    }
    h1 {
        font-size: 2rem;
        justify-self: center;
        align-self: center;
        text-align: center;
    }
    h3 {
        text-align: center;
    }
    h1, h3 {
        color: var(--green);
        margin: 0;
    }

}

.results_grid > * {
    flex-basis: auto;
}

.result_block {
    /**
    IMPORTANT: If any of the values regarding width are changed, change the width of the result_block:nth-child(odd):last-child in result_block class
     */
    display: flex;
    flex-direction: column;
    width: 40%;
    min-width: 710px; // If you change this value, change the width of the result_block:nth-child(odd):last-child in result_block class
    height: 430px;
    background-color: white;
    border: 1px solid var(--lightGreen);
    border-top: 3px solid var(--lightGreen);
    border-radius: 5px;
    position: relative;
    font-size: 12px;
    padding: 25px;
    overflow-y: auto;
    overflow-x: hidden;

    &.group_by {
        width: 80%;
        height: auto;
        .m1 {
            display: flex;
            width: 100%;
            font-size: small;
        }
        .table_overview td th {
            font-size: medium;
        }
    }


    &:nth-child(odd):last-child {
        @media screen and (min-width: 1809px) {
            flex: 0 0 calc(80% + 25px); // 25px is the gap in result grid flex container
            min-width: calc(710px * 2 + 25px); // 25px is the gap in result grid flex container and 770 is the min-width of the result_block
            padding: 50px;
        }
    }
}

.result_card {
    display: flex;
    flex-direction: column;
    height: 100%;
    h5 {
        font-size: 14px;
        font-weight: bold;
    }
    img {
        align-self: center;
    }
}

.svg_switch_button {
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;

    button {
        background-color: transparent;
        font-size: 1.6em;
        padding: .8em 1.4em;

        .current_icon {
            color: black;
        }

        i {
            color: var(--lightGray);
        }

        &:hover {
            background-color: var(--color-button-hover);

            i {
                color: black;
            }
        }
        &:first-child {
            border-radius: 0 0 0 3px;
        }
    }
}

table {
    &.grouped_result_table {
        td, th {
            text-align: left;
        }
    }

    &.result_table {
        td {
            width: 10%;
            &:not(.unit):not(:first-child) {
                font-size: 13px;
                font-weight: bold;
            }
        }
        td:first-child, th:first-child {
            width: 20%;
            text-align: left;
        }
        tr, th {
            text-align: center;
        }
        td, th {
            text-align: right;
        }
    }

    &.result_table, &.grouped_result_table {
        width: 100%;
        min-width: 350px;
        border-collapse: collapse;

        th {
            background: white;
            position: sticky;
            overflow: hidden;
        }
        thead th {
            z-index: 2;
            top: 0;
        }
        tr, th {
            border-bottom: var(--lightGray) 1px solid;
        }
        td, th {
            padding: 15px 7px 15px 7px;
            height: auto;
        }
        td:first-child {
            text-align: left;
        }
    }
}

.result_card_graph {
    display: flex;
    justify-content: center;
    align-content: center;
}

.result_score_box {
    position: relative;
    padding-bottom: 100px;
    box-sizing: border-box;
    display: flex;
}

.result_card_head h5 {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0;
    color: var(--gray);
    max-width: 42%;
}

.result_card_head {
    flex-grow: 1;
    .result_compared_head {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

.result_card_content {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    align-items: center;
    margin-top: 10px;
    position: relative;
    overflow-x: hidden;

    .result_selectors {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 10px;
    }

    img {
        width: 65%;
        max-height: 100%;
    }

	p {
		font-family: Lexend, sans-serif;
	}

    &.table {
        align-items: flex-start;
        overflow: auto;
    }

    &.groupBy {
        padding-top: 0;
    }

	.equivalence_wrapper {
		@include flex-center;

        width: 100%;
		flex-wrap: wrap;
		gap: 30px;
        margin-top: 2%;


		.equivalence {
			@include flex-center;

			position: relative;
			width: 40%;
			height: 130px;
			flex-direction: column;
			gap: 10px;
			border: 2px solid var(--lightGreen);
			-webkit-box-shadow: 4px 4px 6px 1px gray;
			box-shadow: 4px 4px 6px 1px gray;
			border-radius: 5px;
			padding: 20px;

			.icon_container {
				@include flex-center;

				position: absolute;
				padding: 5px 10px 10px 10px;
				top: -20px;
				left: -20px;
				background: white;
				gap: 5px;

				p {
					color: var(--lightGreen);
                    text-align: left;
				}
			}

			.icon {
				@include flex-center;
				width: 30px;
				height: 30px;
			}

			.value {
				font-size: 1.4rem;
				color: var(--lightGreen);
			}

			.unit {
				font-size: 0.7rem;
				font-style: italic;
			}

			p {
				font-size: 0.9rem;
				margin: 0;
				text-align: center;
			}

		}
	}
}

.result_graph_img {
   width: 100%;
    max-height: 370px;
}


.result_arrows {
    margin-top: 10px;
    padding-left: 5px;
    font-size: 1.3em;
    line-height: 0.8em;
}

.result_panel_info {
    display: flex;
    justify-content: center;
    padding: 0 50px 50px 50px;
}

.result_head_bar {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    margin: 10px 0 10px 0;

    .head_button i {
        color: white;
    }

    .danger_button {
        background-color: var(--user-styling-color-danger-mary);
    }

    .choice_selector {
        min-width: 350px;

        i {
            margin-right: 5px;
        }
    }

    .edit_result_launcher_name_input i {
        color: black;
    }

    input {
        width: 30%;
    }
}

.result_head_bar_buttons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.not_compared {
    justify-content: space-around;
}

.result_carousel_container {
    margin: 0 0 40px 0;
    text-align: center;

    .result_carousel_header {
        font-family: 'Roboto', sans-serif;
        font-size: 22px;
        margin-bottom: 20px;
        color: var(--gray);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 15px;

        h5 {
            margin: 0;
            padding: 0;
        }
    }
    .result_carousel {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 25px;
    }


    .carousel_item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border: var(--lightGreen) 1px solid;
        border-radius: 4px;
        background: white;
        min-height: 140px;
        width: 250px;
        padding: 30px 10px 30px 10px;

        p {
            margin: 0;
            text-align: center;
        }
    }
    .carousel_item_icon {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        top: -20px;
        border-radius: 50%;
        background: white;
        border: var(--lightGreen) 1px solid;
    }
    .carousel_item_head {
        display: flex;
        font-size: 0.9em;
    }
    .result_carousel_title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        h5 {
            margin: 10px 0 10px 0;
        }
        .current_title {
            color: var(--user-styling-color-darken-mary);
            font-weight: bold;
        }
        input[type=checkbox]:before {
            background-color: var(--user-styling-color-darken-mary);
        }
    }
    .carousel_item_body {
        display: flex;
        flex-direction: column;

        .item_value {
            font-size: 1.6em;
            font-weight: bold;
        }

        .item_unit {
            font-size: 1em;
        }
    }

    .carousel_item_footer {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 5px;

        p {
            font-size: 0.7em;
        }

        .carousel_item_gradient_bar_wrapper {
            display: flex;
            width: 100%;
            justify-content: center;

            .carousel_item_gradient_bar {
                width: 95%;
                height: 10px;
                border-radius: 10px;
                position: relative;
            }
            .carousel_item_cursor {
                position: absolute;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                transform: translateX(-50%) translateY(50%);
            }

            .perimeter_cursor {
                border-top: 7px solid black;
                bottom: 11px;
            }

            .perimeter_text {
                position: absolute;
                bottom: 15px;
                font-weight: bold;
            }

            .mean_cursor {
                border-bottom: 7px solid black;
                bottom: -1px;
            }

            .mean_text {
                position: absolute;
                text-align: center;
                bottom: -32px;
            }
        }

        .item_min {
            text-align: start;
        }

        .item_max {
            text-align: end;
        }

        .less_than_min {
            color: var(--user-styling-color-darken-mary);
            display: flex;
            align-items: center;
            gap: 3px;
        }
        .more_than_max {
            color: var(--user-styling-color-danger-mary);
            display: flex;
            align-items: center;
            gap: 3px;
        }
    }
}

.edit_result_launcher_name_open{
    color: var(--green);
}

.result_panel_footer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.equipment_version {
    text-decoration: underline;
}

.results_choose_sample {
    text-align: center;
    font-size: 1.3em;
    word-break: break-word;
    width: 300px;
    margin: 0 auto;
}

.result_comparison_impossible {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.result_compared_title {
    padding-top: 5px;
    font-weight: bold;
    font-size: 1.2em;
    margin: 0;
    &.mb {
        font-size: 1em;
        margin-bottom: 15px;
    }
}

.download_graphs{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}