.dashboard_card_color_grey {
    --dashboard-color-light: hsl(200, 0%, 71%);
    --dashboard-color-dark: hsl(201, 0%, 31%);
}
.dashboard_card_color_green_light {
    --dashboard-color-light: #9bbecf;
    --dashboard-color-dark: #086598;
}
.dashboard_card_color_green {
    --dashboard-color-light: #51c589;
    --dashboard-color-dark: #51c589;
}
.dashboard_card_color_yellow {
    --dashboard-color-light: #f3eab1;
    --dashboard-color-dark: #edcc22;
}
.dashboard_card_color_blue {
    --dashboard-color-light: #9bbecf;
    --dashboard-color-dark: #086598;
}
.dashboard_card_color_red {
    --dashboard-color-light: #ddb2b2;
    --dashboard-color-dark: #e67171;
}

.dashboard_card {
    margin: 10px 0;
    border: 1px solid var(--dashboard-color-light);
    border-top: 3px solid var(--dashboard-color-dark);
    border-radius: 5px;
    background-color: #fcfdfc;
    padding: 10px;
}

.dashboard_card_info {
    position: relative;
}

.dashboard_card_info .close_button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
}

.dashboard_card_content {
    padding: 10px;
    background-color: #f5f6f5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 15px;

    select {
        padding: 5px 10px;
    }

    .dashboard_custom_select {
        width: 300px;
        position: relative;
        background-color: white;
        padding: 7px 10px;

        .dashboard_custom_select_button {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: white;
            font-weight: bold;
            font-size: 0.8em;
            padding: 0;

            i {
                font-size: 0.75em;
                color: #737373;
            }
        }

        .dashboard_modal_dataset_name {
            text-align: start;
        }
    }

    select, .dashboard_custom_select {
        border: 1px solid var(--dashboard-color-dark, darkgray);
        border-radius: 5px;
        font-weight: bold;
        min-width: 200px;
    }
}

.dashboard_card_acv_result {
    width: 710px;
    min-height: 350px;
}

.dashboard_card_selected {
    border: 1px solid grey;
    border-radius: 5px;
    background-color: #fcfdfc;
    padding: 5px 10px;

    .bold {
        font-weight: bold;
    }
}

.dashboard_card_content_select_box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.dashboard_card_content_select_box label {
    font-size: .7em;
    font-weight: bold;
    margin-right: 10px;
}

.dashboard_graph {
    position: relative;
    width: 100%;
    min-height: 300px;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.dashboard_chart_container {
    flex: 1 1 100%;
    width: 100%;
    height: 250px;
}

.chart_indicator_unit {
    font-size: 0.8em;
    width: fit-content;
    padding: 5px;
    font-weight: bold;
}

.dashboard_graph_multiple {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.dashboard_graph_multiple .dashboard_graph_multiple_graph {
    flex: 1 1 100%;
}
.dashboard_graph_multiple .dashboard_graph_multiple_graph .dashboard_graph_multiple_graph_container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.dashboard_graph_multiple .dashboard_graph_multiple_head {
    flex: 1 1 150px;
    width: 150px;
    font-size: 0.8em;
}

.dashboard_graph_multiple h4 {
    padding: 0;
    margin: 0 0 10px;
}

.dashboard_graph_multiple .dashboard_graph_tips {
    flex: 1 1 60px;
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
}

.dashboard_graph_multiple .dashboard_graph_tips .dashboard_action {
    --color-icon: #606060;
}
.dashboard_graph_multiple .dashboard_graph_tips .dashboard_action .dashboard_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 25px;
    border: 2px solid var(--color-icon);
    cursor: pointer;
    color: var(--color-icon);
    font-size: .8em;
    position: relative;
    top: 0;
    right: 0;
}

.dashboard_graph_multiple .dashboard_graph_tips .dashboard_action.disabled {
    opacity: 0.2;
    cursor: not-allowed;
}
.dashboard_graph_multiple .dashboard_graph_tips .dashboard_action.disabled .dashboard_icon {
    cursor: not-allowed;
}

.dashboard_graph_legend {
    border-radius: 5px;
    border: 1px solid var(--dashboard-color-light);
    padding: 10px;
    background-color: #f5f6f5;
    box-sizing: border-box;
    word-break: keep-all;
    min-width: 200px;
}
.dashboard_graph_legend span {
    content: ' ';
    width: 40px;
    height: 10px;
    display: inline-block;
    margin-right: 10px;
}

table.dashboard_graph_list {
    border-bottom: 1px solid dimgray;
    width: 100%;
    font-size: 0.8em;
    margin: 20px 0;
    box-sizing: border-box;
}

table.dashboard_graph_list tr {
    box-sizing: border-box;
}

table.dashboard_graph_list tr th span:not(:first-child),
table.dashboard_graph_list tr td span:not(:first-child) {
    color: red;
}

table.dashboard_graph_list tr th:first-child,
table.dashboard_graph_list tr td:first-child {
    text-align: left;
    width: 30%;
}

table.dashboard_graph_list tr th {
    display: table-cell;
    flex-direction: column;
}
table.dashboard_graph_list tr td {
    padding: 6px 0;
    box-sizing: border-box;
    position: relative;
}
table.dashboard_graph_list tr td:not(:first-child) {
    padding: 6px;
}
table.dashboard_graph_list tr td:last-child {
    padding-left: 30px;
}
table.dashboard_graph_list tr th:not(:first-child){
    text-align: center;
}
table.dashboard_graph_list tr td:not(:first-child) {
    border-bottom: 1px solid #dadada;
    text-align: center;
    position: relative;
}
table tr th span.number,
table tr td span.number {
    position: relative;
    text-align: right;
}
table tr th span.ref,
table tr td span.ref {
    font-size: 0.8em;
}

table tr th span.number.ref,
table tr td span.number.ref {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(5px, -50%);
}

table.dashboard_graph_list h3,
table.dashboard_graph_list h4 {
    margin: 0;
}

.dashboard_card_content_actions {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.dashboard_card_content_actions .dashboard_action {
    --color-icon: #606060;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.dashboard_card_content_actions .dashboard_action .dashboard_icon {
    width: 25px;
    height: 25px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;
    border: 2px solid var(--color-icon);
    cursor: pointer;
    color: var(--color-icon);
}

.dashboard_card_content_actions .dashboard_action .dashboard_text {
    color: var(--color-icon);
    cursor: pointer;
    width: 100px;
    line-break: normal;
}

.dashboard_card_content_actions .dashboard_action.disabled {
    opacity: 0.2;
    cursor: not-allowed;
}
.dashboard_card_content_actions .dashboard_action.disabled .dashboard_icon,
.dashboard_card_content_actions .dashboard_action.disabled .dashboard_text {
    cursor: not-allowed;
}

.dashboard_table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 0.8em;
}

.dashboard_table tr th,
.dashboard_table tr td {
    padding: 15px;
    border-bottom: 1px solid #dadada;
    text-align: left;
}
.dashboard_table tr th:not(:first-child),
.dashboard_table tr td:not(:first-child) {
    width: 80px;
}

.dashboard_table .align-right,
.dashboard_table .value {
    text-align: right;
}