.resilio_arrow {
    width: 68px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
}

.resilio_arrow.large {
    position: fixed;
    width: 30%;
    z-index: -1;
}

.resilio_arrow img {
    width: 68px;
}
