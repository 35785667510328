.nav_short_cut {
	position: fixed;
	left: -100%;
	top: 0;
	z-index: 1000;
	background-color: 'black';
}
.nav_short_cut:focus {
	left: 10px;
}
