.label_list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.label {
  display: inline-flex;
  align-items: center;
  padding: 0 6px;
  border: 2px solid var(--gray);
  border-radius: 3px;
  color: var(--gray);
  font-size: 13px;
  font-weight: bold;

  &_selectable {
    cursor: pointer;
    transition: all 0.2s ease;
  }

  &_selected {
    border: 2px solid var(--user-styling-color-darken-mary);
    color: var(--user-styling-color-darken-mary);
  }
}