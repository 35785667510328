.color_settings {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
	max-width: 75vh;
	hr {
		box-sizing: border-box;
		width: 100%;
		padding: 0;
	}
	.colors_selection {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 10px;
		width: 100%;
		.color_line {
			display: flex;
			align-items: center;
			width: 100%;
			justify-content: space-between;
			p {
				margin-right: 10px;
				max-width: 75%;
			}
			.color_selector {
				position: relative;
				width: 20%; 
				min-height: 20px;
				cursor: pointer;
				border-radius: 5px;
				box-shadow: 0 1px 3px rgba(0,0,0,0.2);
				transition: background-color 0.2s ease-in-out;
				.color_container{
					padding: 0.5rem;
					gap: 0.2rem;
					cursor: default;
					z-index: 999;
					max-width: 200px;
					position: fixed;
					display: flex;
					flex-wrap: wrap;
					background-color: #dfdfdf;
					box-shadow: 0 1px 3px rgba(0,0,0,0.2);
					.color_palette {
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
						.color_palette_item {
							width: 20px;
							height: 20px;
							margin: 5px;
							cursor: pointer;
							&.selected {
								border: 3px solid #000;
							}
							&:hover {
								filter: brightness(1.2);
							}
						}
					}
					.choose_color {
						position: relative;
						display: flex;
						align-items: center;
						width: 100%;
						align-content: center;
						gap: 5px;
						justify-content: center;
						background-color: rgba(255, 255, 255, 0.382);
						border-radius: 5px;
						box-shadow: 0 1px 3px rgba(0,0,0,0.2);
						.color_input {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							opacity: 0;
							cursor: pointer;
						}
						p {
							margin-left: 5px; 
						}
					}
				}
			}
		}
	}
}