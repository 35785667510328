.global_modal {
	&.active {
		position: fixed;
		z-index: 100;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		backdrop-filter: blur(10px);
	}

	display: none;

	&_content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: white;
		align-items: center;
		border: none;
		border-radius: 5px;
		padding: 15px 25px;
		gap: 20px;
		max-width: 70vw;
		max-height: 90vh;
		box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
		position: relative;

		& > div {
			background-color: white;
		}

		.modal_header {
			max-width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: left;
			align-items: center;
			align-self: flex-start;
			color: var(--user-styling-color-darken-mary);
			font-size: 1.5em;
			font-weight: bold;
			padding: 0;
			margin: 10px 0 0 0;

			h2 {
				margin: 0;
				padding: 0;
				max-width: 100%;
				word-break: break-all;
			}
		}

		.modal_close_button {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			position: absolute;
			width: 16px;
			height: 16px;
			right: 5px;
			top: 5px;
			color: var(--gray);
			background: transparent;
		}

		.modal_body {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			width: 100%;
			height: 80%;
			font-weight: bold;
			text-align: left;
			color: var(--gray);
			font-size: 1em;
			padding: 0 0;

			p {
				margin: 0 0;
			}
		}

		.modal_footer {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 10px;
			width: 100%;
			height: 15%;
			padding: 0 0;

			.modal_footer_additional_content {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				width: 100%;
				gap: 10px;
			}

			.modal_button {
				display: flex;
				justify-content: center;
				align-items: center;
				min-width: 100px;
				height: 100%;
				border-radius: 5px;

				&:hover {
					background-color: var(--color-button-hover);
				}
			}

			.modal_button:disabled {
				cursor: default;
				background-color: var(--lightGray);
			}

			.validate_modal_button {
				background-color: var(--user-styling-color-darken-mary);
				color: white;

				&:hover {
					background-color: var(--user-styling-color-light-mary);
				}
			}
		}
	}

	.danger_modal_button {
		background-color: var(--user-styling-color-danger-mary);
		color: white;
		font-size: 1em;
	}
}
  