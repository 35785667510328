.account {
    position: absolute;
    z-index: 100;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    left: -25%;
    top: 50%;
}

.account .capsule {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid grey;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    cursor: pointer;
    color: black;
}
.account .capsule:hover, .account .capsule:focus {
    border-width: 2px;
}
.account .upperLetter {
    font-weight: bold;
}

.account .card {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 99;
    right: 50%;
    top: 50%;
    min-width: 0;
    max-width: 0;
    max-height: 0;
    opacity: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    transition: .2s;
    overflow: hidden;
    visibility: hidden;
    background-color: white;
}
.account .card.open {
    right: -20px;
    top: -20px;
    opacity: 1;
    min-width: 200px;
    max-width: 500px;
    max-height: 500px;
    padding: 70px 10px 10px;
    visibility: visible;
}

.account .card > div {
    margin: 10px 0;
}

.account .card .links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.account .card .links a {
    margin: 5px 0;
    color: #1a1a1a;
}
.account .card .links span {
    margin: 5px 0;
    color: lightgrey;
    pointer-events: none;
}
.account .card .links a:hover {
    font-weight: bold;
}

.account .card button {
    border: 2px solid lightgray;
    border-radius: 10px;
    background-color: transparent;
    padding: 3px 20px;
    font-size: 14px;
    cursor: pointer;
}

.account .card button:hover {
    border-color: gray;
    font-weight: bold;
}