.tooltip_text{
    font-size:  15px;
    color: var(--user-styling-color-darken-mary);
    background-color: #F9F9F9;
    border-radius: 4px;
    min-height: 18px;
    z-index: 112;
    text-align: left;
    align-items: center;
    justify-content: center;
    border: 0.1px solid #47835b;
    padding: 5px;
    margin-top: 10px;
    max-width: 500px;
    pointer-events: none;
}
