.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 85%;
    height: 56px;
    gap: 20px;
    margin: 43px 0 20px;
    padding: 0 20px;

    .update_button {
        display: flex;
        background: transparent;
        gap: 10px;
        font-weight: bold;
        color: var(--user-styling-color-darken-mary);

        &:hover {
            cursor: pointer;

            .update_button_text {
                text-decoration: underline;
            }
        }
    }
}

.datasetSelector select {
    border: none;
    padding: 4px 10px;
    border-radius: 5px;
    font-weight: bold;
    outline: none;
}
.datasetSelector select option {
    font-weight: bold;
}
.datasetSelector .datasetSelector_indicator {
    font-size: 12px;
}


