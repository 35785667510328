.study_title {
    display: flex;
    align-items: center;
    gap: 10px;

    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        color: var(--gray);
    }

    .study_actions {
        display: flex;
        gap: 10px;

        button {
            padding: 0;

            i {
                font-size: 1.1rem;
            }
        }
    }
}
