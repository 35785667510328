.loading-wrap {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  img {
    width: 500px;
    height: 100px;
  }

  .leaf-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    transition: all 200ms ease-in-out;

    svg, img {
      &.green { color: var(--user-styling-color-darken-mary); fill: var(--user-styling-color-darken-mary) }
      &.gray { color: var(--gray); fill: var(--gray) }
    }

    svg {
      transition: all 200ms ease-in-out;
    }
  }
}