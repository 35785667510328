.info-samples {
  display: flex;
  flex-direction: column;

  &_title {
    margin: 5px 0;
    font-size: 0.8em;
    line-height: 1.2;
  }

  &_content {
    margin-top: 10px;
    font-size: 0.8em;

    p {
      margin: 5px 0;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}