@mixin flex-center-box {
	display: flex;
	justify-content: center;
	align-items: center;
}

.user_notification_wrapper {
	display: none;

	&.active {
		@include flex-center-box;

		position: fixed;
		z-index: 100;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		backdrop-filter: blur(10px);
	}


	.user_notification {
		@include flex-center-box;
		flex-direction: column;
		gap: 25px;

		max-width: 900px;
		max-height: 70vh;

		position: relative;
		padding: 50px;
		border: none;
		border-radius: 15px;
		box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
		color: white;
		text-align: center;
		background-color: var(--user-styling-color-darken-mary);

		&.warning {
			background-color: var(--yellow-corp-color);
			color: black;
			font-weight: bold;
			.close {
				color: black;
			}
			a {
				color: black;
			}
		}

		&.error {
			background-color: var(--red-corp-color);
			font-weight: bold;
		}

		.close {
			position: absolute;
			top: 15px;
			right: 15px;
			color: white;
		}

		a {
			color: white;
		}

		.user_notification_title {
			font-size: 20px;
		}

		.show_list_button {
			background-color: transparent;
			color: black;
			text-decoration: underline;
		}

		.user_notification_list {
			@include flex-center-box;
			gap: 13px;
			flex-direction: column;
			h4 {
				margin: 0;
			}
			.user_notification_list_items {
				@include flex-center-box;
				padding: 0 10px;
				flex-direction: column;
				gap: 10px;
				max-height: 50vh;
				overflow-y: auto;
			}
		}

		.user_notification_footer {
			@include flex-center-box;
			gap: 20px;

			button {
				min-width: 80px;
				padding: 10px;
				border-radius: 5px;
				font-weight: bold;
			}

			.user_notification_redirection {
				background-color: white;
				color: var(--user-styling-color-darken-mary);
			}

			.user_notification_close {
				background-color: var(--user-styling-color-darken-mary);
				color: white;
				border: 1px solid white;
			}
		}
	}
}