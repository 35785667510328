.dashboard_modal_table_creation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 35px;
  padding: 20px;

  .dashboard_modal_table_creation_image {
    flex: 1 1 auto;
  }

  .dashboard_modal_table_creation_form {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    .dashboard_modal_table_creation_form_input {
      display: flex;
      flex-direction: column;
      gap: 5px;

      label {
        text-align: left;
      }
    }
  }
}