.modal_wrapper {
	min-width: 60vw;
	font-weight: normal;

	.title_wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;

		i {
			color: var(--gray);
		}
		button {
			background-color: transparent;
			padding: 0;
		}
	}

	textarea {
		width: 100%;
		box-sizing: border-box;
		resize: vertical;
	}

	input[type="checkbox"] {
		border-radius: 0 !important;
		width: 20px !important;
		height: 20px !important;
		margin: 2px;
	}

	input[type=checkbox]:before {
		content: none;
	}

	input[type=checkbox]:checked {
		background-color: dimgrey;
	}
}