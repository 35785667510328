.version_table_wrapper {
	overflow: auto;

	.version_table {
		th:first-child {
			text-align: left;
		}

		th i {
			margin-left: 5px;
		}
		a {
			color: black;
		}
		.data_reference {
			max-width: 150px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

