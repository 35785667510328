.choice_selector {
    display: flex;
    font-size: 0.9em;
    position: relative;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 7px;
    min-height: 25px;
}

.choice_selector:hover {
    border-color: #999;
}

.choice_selector label {
    position: absolute;
    top: -13px;
    left: 10px;
    font-size: 0.85em;
    background-color: #EFF1F0;
    padding: 4px;
}

.choice_selector_button {
    background-color: transparent;
    padding: 4px;
    font-size: .9rem;
    position: relative;
    flex-grow: 1;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}

.choice_selector_button_content {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.choice_selector_button i {
    color: var(--gray);
}

.choice_selector.open .choice_selector_button {
    visibility: hidden;
    opacity: 0;
}

.choice_selector_button_disabled {
    cursor: default;
}

input[type=text].choice_selector_input_filter {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    top: 6px;
    left: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 12px);
    padding: 0 8px;
}

.choice_selector.open .choice_selector_input_filter {
    visibility: inherit;
    opacity: 1;
}

.choice_selector_list {
    position: fixed;
    z-index: 1000;
    transform: translateX(0);
    width: auto;
    margin: 0;
    padding: 0;
    border: 1px solid black;
    border-radius: 3px;
    flex-direction: column;
    display: none;
    max-height: 400px;
    overflow-y: auto;
    white-space: nowrap;
}

.choice_selector.open .choice_selector_list {
    display: flex;
}

.choice_selector_list_elem {
    list-style: none;
    margin: 0;
    width: 100%;
    background-color: white;
    font-size: .9em;
    padding: .5rem .6rem;
    box-sizing: border-box;
    cursor: pointer;
    white-space: break-spaces;
}

.choice_selector_list_elem.selected {
    background-color: var(--user-styling-color-light);
}

.choice_selector_list_elem.disabled {
    background-color: #8c8c8c;
    color: #313131;
}

.choice_selector_list_button {
    border: none;
    background-color: white;
    font-weight: bold;
    font-size: .9em;
    padding: .5rem .6rem;
    width: 100%;
}

button.choice_selector_list_button:hover, button.choice_selector_list_button:focus-visible {
    background-color: #eee;
    outline: none;
    filter: none;
}

.choice_selector_list_elem:hover, .choice_selector_list_elem:focus-visible {
    background-color: #eee;
    outline: none;
    filter: none;
}

.choice_selector_list_elem.disabled:hover, .choice_selector_list_elem.disabled:focus-visible {
    background-color: #8c8c8c;
    outline: none;
    filter: none;
}
