.notifications-container {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 101;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.notification {
  width: 35rem;
  border-radius: 0.75rem;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-left-width: 4px;
  border-left-style: solid;
  transform: translateX(0);
  opacity: 1;
  animation: slideIn 0.3s ease-out;

  @keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &.hidden {
    animation: slideOut 0.3s ease-in forwards;
  }

  @keyframes slideOut {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(100%);
      opacity: 0;
    }
  }

  &--success {
    border-left-color: var(--user-styling-color-darken-mary);
    .notification__icon {
      color: var(--user-styling-color-darken-mary);
    }
  }

  &--info {
    border-left-color: #60a5fa;
    .notification__icon {
      color: #60a5fa;
    }
  }

  &--warning {
    border-left-color: #fbbf24;
    .notification__icon {
      color: #fbbf24;
    }
  }

  &--error {
    border-left-color: var(--user-styling-color-danger-mary);
    .notification__icon {
      color: var(--user-styling-color-danger-mary);
    }
  }

  &__content {
    display: flex;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    flex-shrink: 0;
  }

  &__icon {
    font-size: 1.25rem;
  }

  &__body {
    flex: 1;
    min-width: 0;
  }

  &__message {
    margin: 0;
    font-size: 1rem;
    color: var(--gray);
    line-height: 1.5;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-left: auto; // This pushes the actions to the right
  }

  &__action-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    padding: 0;
    border-radius: 50%;
    color: var(--gray);
    background-color: transparent;
    transition: all 0.2s ease;
    cursor: pointer;
    border: none;

    &:hover {
      background-color: #f3f4f6;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(var(--gray-rgb), 0.2);
    }
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    padding: 0;
    border-radius: 50%;
    color: var(--gray);
    background-color: transparent;
    transition: all 0.2s ease;
    cursor: pointer;
    border: none;

    &:hover {
      background-color: #f3f4f6;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(var(--gray-rgb), 0.2);
    }

    &-icon {
      font-size: 1rem;
    }
  }
}