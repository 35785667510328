.doughnut_relative_chart{
	max-height: 330px;
}

.doughnut_progress_chart{
	max-height: 330px;
}

.doughnut_progress_chart_description{
	font-size: 18px;
	margin-bottom: 0;
	font-weight: bold;
	color: var(--gray);
}