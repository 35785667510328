.user_manage_content {
    margin-top: 10px;
}
.user_manage_line {
    background-color: white;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 5px 10px;
    margin: 3px 0;
}
.user_manage_line_top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 auto;
}
.user_manage_line_top button.info {
    background-color: transparent;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 15px;
}
.user_manage_line_top button.info:hover {
    filter: none;
}

.user_manage_line_collapse {
    --height-size: auto;
    margin: 0 .8rem;
    height: var(--height-size);
    max-height: 0;
    transition: .2s ease-in-out;
    overflow: hidden;
    visibility: hidden;
}
.user_manage_line_collapse.open {
    margin: .6rem .8rem;
    max-height: var(--height-size);
    visibility: visible;
}
.user_manage_sample_result_button {
    display: flex;
    height: fit-content;
}

.user_manage_line_bottom {
    display: flex;
    flex-direction: column;
    background-color: #eff1f0;
    padding: 15px;
    border-radius: 3px;
    margin: 10px 0 3px 0;
}
.user_manage_line_error {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.user_manage_separator {
    height: 0;
    margin: 5px;
    width: 5px;
}
.user_manage_button_small {
    padding: 2px 5px;
    font-size: 0.9em;
}

.user_manage_permissions_header {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #edefee;
}

.user_manage_permissions_header .search_bar {
    margin-top: 15px;
}

.user_manage_permissions_lines {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 10px 0;
}
.user_manage_permissions_line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 1 1 auto;
    background-color: white;
    border-radius: 100px;
    margin: 1px 0;
    padding: 2px 10px;
}
.user_manage_permissions_line input[type=checkbox] {
    vertical-align: sub;
    margin: 0;
}
.user_manage_permission_separator {
    height: 0;
    width: 10px;
    margin: 8px;
}
.user_manage_permissions_save_checked {
    display: flex;
    align-items: center;
    margin: 0 10px;
}
.select-container {
    width: 100%;
}
.user_manage_multi_selector {
    display: flex;
    flex-direction: column;
    margin: 6px;
    max-height: 250px;
}

.user_manage_study_consultants.search_bar {
    width: auto;
}

.user_manage_study_consultants {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.user_manage_study_consultants .multi_choice_selector {
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.user_manage_study_consultants .multi_choice_selector_list {
    overflow-y: auto;
}


.user_manage_top_header {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.icon_circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 100%;
}

.modal_user_manage_success {
    padding: 20px;
    text-align: center;
}

.modal_user_manage_success a {
    color: black;
}

.manage_study_infos {
    font-size: 0.9rem;
    font-weight: bold;
    color: var(--gray);
}

.manage_input_translation_wrapper {
    display: flex;
    gap: 10px;
}